import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// --- Internal Components ---
import i18n from "./../i18n";
import LandingPage from "../pages/LandingPage";
import Privacy from "../pages/LandingPage/Privacy";
import Conditions from "../pages/LandingPage/Conditions";
import Pricing from "../pages/LandingPage/Pricing";
import BlogPage from "./../pages/LandingPage/BlogsPage";
import AboutUs from "./../pages/LandingPage/AboutUs";
import BlogOne from "./../components/Blogs/BlogOne.jsx";
import BlogTwo from "./../components/Blogs/BlogTwo.jsx";
import BlogThree from "./../components/Blogs/BlogThree.jsx";
import BlogFour from "./../components/Blogs/BlogFour.jsx";
import BlogFive from "./../components/Blogs/BlogFive.jsx";
import BlogSix from "./../components/Blogs/BlogSix.jsx";

// --- Slug Mapping ---
const blogSlugs = {
  1: "ai-in-sales",
  2: "how-ai-is-revolutionizing-customer-service-in-2024",
  3: "chatgpt-vs-gemini",
  4: "12-best-intercom-alternatives-for-customer-support-in-2024",
  5: "15-best-customer-service-automation-software",
  6: "top-freshworks-alternatives",
};

// --- MainRoutes Component ---
const MainRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get("lang");
    if (langParam && i18n.language !== langParam) {
      i18n.changeLanguage(langParam);
      localStorage.setItem("selectedLanguage", langParam);
    } else {
      const storedLanguage = localStorage.getItem("selectedLanguage") || "en";
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/about" element={<AboutUs />} />
      <Route exact path="/pricing" element={<Pricing />} />
      <Route exact path="/blog" element={<BlogPage />} />
      <Route exact path="/privacy-policy" element={<Privacy />} />
      <Route exact path="/terms-and-conditions" element={<Conditions />} />
      <Route exact path={`/blog/${blogSlugs[1]}`} element={<BlogOne />} />
      <Route exact path={`/blog/${blogSlugs[2]}`} element={<BlogTwo />} />
      <Route exact path={`/blog/${blogSlugs[3]}`} element={<BlogThree />} />
      <Route exact path={`/blog/${blogSlugs[4]}`} element={<BlogFour />} />
      <Route exact path={`/blog/${blogSlugs[5]}`} element={<BlogFive />} />
      <Route exact path={`/blog/${blogSlugs[6]}`} element={<BlogSix />} />
    </Routes>
  );
};

export default MainRoutes;
