// --- Header Component Images ---
import logo from "../assets/img/horizontal-logo-for-black-background.png";

// --- Main Component Images ---
import inbox from "../assets/img/inbox.png";
import co_pilot from "../assets/img/co_pilot.png";
import ai_agents from "../assets/img/ai_agents.png";
import tickets from "../assets/img/tickets.png";
import omnichannel_1 from "../assets/img/omnichannel_1.png";
import omnichannel_2 from "../assets/img/omnichannel_2.png";
import omnichannel_3 from "../assets/img/omnichannel_3.png";
import omnichannel_icon_1 from "../assets/img/omnichannel_icon_1.png";
import omnichannel_icon_2 from "../assets/img/omnichannel_icon_2.png";
import omnichannel_icon_3 from "../assets/img/omnichannel_icon_3.png";
import omnichannel_icon_4 from "../assets/img/omnichannel_icon_4.png";
import omnichannel_icon_5 from "../assets/img/omnichannel_icon_5.png";
import reporting from "../assets/img/reporting.png";
import workflows from "../assets/img/workflows.png";
import integrations from "../assets/img/integrations.png";
import segments from "../assets/img/segments.png";

// --- Benefits Component Images ---
import benefits_ai_agents from "../assets/img/benefits_ai_agents.gif";
import benefits_omnichannel from "../assets/img/benefits_omnichannel.png";
import benefits_tickets from "../assets/img/benefits_tickets.png";
import benefits_broadcast_messaging from "../assets/img/benefits_broadcast_messaging.png";
import benefits_segments from "../assets/img/benefits_segments.png";
import benefits_swift_copilot from "../assets/img/benefits_swift_copilot.png";
import benefits_ai_insights from "../assets/img/benefits_ai_insights.png";
import benefits_workflows from "../assets/img/benefits_workflows.png";

// --- Testimonials Component Images ---
import apple_logo from "../assets/img/apple_logo.svg";
import google_logo from "../assets/img/google_logo.svg";
import open_ai_logo from "../assets/img/open_ai_logo.svg";
import testimonial_avatar_1 from "../assets/img/testimonial_avatar_1.png";
import testimonial_avatar_2 from "../assets/img/testimonial_avatar_2.png";
import testimonial_avatar_3 from "../assets/img/testimonial_avatar_3.png";

// --- TryForFree Component Images ---
import Logomark from "../assets/img/Logomark.png";

// --- Blog Header Component Images ---
import header_img_article_1 from "./../assets/img/picture-article_1.png";
import header_img_article_2 from "./../assets/img/picture-article_2.png";
import header_img_article_3 from "./../assets/img/picture-article_3.png";
import header_img_article_4 from "./../assets/img/picture-article_4.png";
import header_img_article_5 from "./../assets/img/picture-article_5.png";
import header_img_article_6 from "./../assets/img/picture-article_6.png";

// --- Blog Second Article ---
import article_2_img_1 from "../assets/img/table-for-second-article.png";

// --- Blog Third Article ---
import article_3_img_1 from "../assets/img/first_img_third_blog.png";
import article_3_img_2 from "../assets/img/second_img_third_blog.png";
import article_3_img_3 from "../assets/img/third__img_third_blog.png";

// --- Blog Fourth Article ---
import article_4_img_1 from "../assets/img/first_img_fourth_blog.png";
import article_4_img_2 from "../assets/img/second_img_fourth_blog.png";
import article_4_img_3 from "../assets/img/third_img_fourth_blog.png";
import article_4_img_4 from "../assets/img/fourth_img_fourth_blog.png";
import article_4_img_5 from "../assets/img/fifth_img_fourth_blog.png";
import article_4_img_6 from "../assets/img/sixth_img_fourth_blog.png";
import article_4_img_7 from "../assets/img/seventh_img_fourth_blog.png";
import article_4_img_8 from "../assets/img/eighth_img_fourth_blog.png";
import article_4_img_9 from "../assets/img/ninth_img_fourth_blog.png";
import article_4_img_10 from "../assets/img/tenth_img_fourth_blog.png";
import article_4_img_11 from "../assets/img/eleventh_img_fourth_blog.png";
import article_4_img_12 from "../assets/img/twelfth_img_fourth_blog.png";
import article_4_img_13 from "../assets/img/thirteenth_img_fourth_blog.png";
import article_4_img_14 from "../assets/img/fourteenth_img_fourth_blog.png";
import article_4_img_15 from "../assets/img/fifteenth_img_fourth_blog.png";
import article_4_img_16 from "../assets/img/sixteenth_img_fourth_blog.png";
import article_4_img_17 from "../assets/img/seventeenth_img_fourth_blog.png";
import article_4_img_18 from "../assets/img/eighteenth_img_fourth_blog.png";
import article_4_img_19 from "../assets/img/nineteenth_img_fourth_blog.png";
import article_4_img_20 from "../assets/img/twentieth_img_fourth_blog.png";
import article_4_img_21 from "../assets/img/twenty_first_img_fourth_blog.png";
import article_4_img_22 from "../assets/img/twenty_second_img_fourth_blog.png";
import article_4_img_23 from "../assets/img/twenty_third_img_fourth_blog.png";
import article_4_img_24 from "../assets/img/twenty_fourth_img_fourth_blog.png";

// --- Blog Fifth Article ---
import article_5_img_1 from "../assets/img/first_img_fourth_blog.png";
import article_5_img_2 from "../assets/img/second_img_fifth_blog.png";
import article_5_img_3 from "../assets/img/third_img_fifth_blog.png";
import article_5_img_4 from "../assets/img/fourth_img_fifth_blog.png";
import article_5_img_5 from "../assets/img/fifth_img_fifth_blog.png";
import article_5_img_6 from "../assets/img/sixth_img_fifth_blog.png";
import article_5_img_7 from "../assets/img/seventh_img_fifth_blog.png";
import article_5_img_8 from "../assets/img/eighth_img_fifth_blog.png";
import article_5_img_9 from "../assets/img/ninth_img_fifth_blog.png";
import article_5_img_10 from "../assets/img/tenth_img_fifth_blog.png";
import article_5_img_11 from "../assets/img/eleventh_img_fifth_blog.png";
import article_5_img_12 from "../assets/img/twelfth_img_fifth_blog.png";
import article_5_img_13 from "../assets/img/thirteenth_img_fifth_blog.png";
import article_5_img_14 from "../assets/img/fourteenth_img_fifth_blog.png";
import article_5_img_15 from "../assets/img/fifteenth_img_fifth_blog.png";
import article_5_img_16 from "../assets/img/sixteenth_img_fifth_blog.png";
import article_5_img_17 from "../assets/img/seventeenth_img_fifth_blog.png";
import article_5_img_18 from "../assets/img/eighteenth_img_fifth_blog.png";
import article_5_img_19 from "../assets/img/nineteenth_img_fifth_blog.png";
import article_5_img_20 from "../assets/img/twentieth_img_fifth_blog.png";
import article_5_img_21 from "../assets/img/twenty_first_img_fifth_blog.png";
import article_5_img_22 from "../assets/img/twenty_second_img_fifth_blog.png";
import article_5_img_23 from "../assets/img/twenty_third_img_fifth_blog.png";
import article_5_img_24 from "../assets/img/twenty_fourth_img_fifth_blog.png";
import article_5_img_25 from "../assets/img/twenty_fifth_img_fifth_blog.png";
import article_5_img_26 from "../assets/img/twenty_sixth_img_fifth_blog.png";
import article_5_img_27 from "../assets/img/twenty_seventh_img_fifth_blog.png";
import article_5_img_28 from "../assets/img/twenty_eighth_img_fifth_blog.png";
import article_5_img_29 from "../assets/img/twenty_ninth_img_fifth_blog.png";
import article_5_img_30 from "../assets/img/thirtieth_img_fifth_blog.png";
import article_5_img_31 from "../assets/img/thirty_first_img_fifth_blog.png";
import article_5_img_32 from "../assets/img/thirty_second_img_fifth_blog.png";
import article_5_img_33 from "../assets/img/thirty_third_img_fifth_blog.png";
import article_5_img_34 from "../assets/img/thirty_fourth_img_fifth_blog.png";
import article_5_img_35 from "../assets/img/thirty_fifth_img_fifth_blog.png";
import article_5_img_36 from "../assets/img/thirty_sixth_img_fifth_blog.png";
import article_5_img_37 from "../assets/img/thirty_seventh_img_fifth_blog.png";
import article_5_img_38 from "../assets/img/thirty_eighth_img_fifth_blog.png";
import article_5_img_39 from "../assets/img/thirty_ninth_img_fifth_blog.png";
import article_5_img_40 from "../assets/img/fortieth_img_fifth_blog.png";
import article_5_img_41 from "../assets/img/forty_first_img_fifth_blog.png";
import article_5_img_42 from "../assets/img/forty_second_img_fifth_blog.png";
import article_5_img_43 from "../assets/img/forty_third_img_fifth_blog.png";
import article_5_img_44 from "../assets/img/forty_fourth_img_fifth_blog.png";
import article_5_img_45 from "../assets/img/forty_fifth_img_fifth_blog.png";

// --- Blog Sixth Article ---
import article_6_img_3 from "../assets/img/article_6_img_3.png";
import article_6_img_4 from "../assets/img/article_6_img_4.png";
import article_6_img_5 from "../assets/img/article_6_img_5.png";
import article_6_img_6 from "../assets/img/article_6_img_6.png";
import article_6_img_7 from "../assets/img/article_6_img_7.png";
import article_6_img_8 from "../assets/img/article_6_img_8.png";
import article_6_img_9 from "../assets/img/article_6_img_9.png";
import article_6_img_10 from "../assets/img/article_6_img_10.png";
import article_6_img_11 from "../assets/img/article_6_img_11.png";
import article_6_img_12 from "../assets/img/article_6_img_12.png";
import article_6_img_13 from "../assets/img/article_6_img_13.png";
import article_6_img_14 from "../assets/img/article_6_img_14.png";
import article_6_img_15 from "../assets/img/article_6_img_15.png";
import article_6_img_16 from "../assets/img/article_6_img_16.png";
import article_6_img_17 from "../assets/img/article_6_img_17.png";
import article_6_img_18 from "../assets/img/article_6_img_18.png";
import article_6_img_19 from "../assets/img/article_6_img_19.png";
import article_6_img_20 from "../assets/img/article_6_img_20.png";


// --- Awards Component Images ---
import product_of_the_week from "../assets/img/product_of_the_week.png";

// --- Export Images ---
export const headerImages = {
    logo,
};

export const mainImages = {
    inbox,
    co_pilot,
    ai_agents,
    tickets,
    omnichannel: {
        main: [omnichannel_1, omnichannel_2, omnichannel_3],
        icons: [
            omnichannel_icon_1,
            omnichannel_icon_2,
            omnichannel_icon_3,
            omnichannel_icon_4,
            omnichannel_icon_5
        ]
    },
    reporting,
    workflows,
    integrations,
    segments,
};

export const benefitsImages = {
    ai_agents: benefits_ai_agents,
    omnichannel: benefits_omnichannel,
    tickets: benefits_tickets,
    broadcast_messaging: benefits_broadcast_messaging,
    segments: benefits_segments,
    swift_copilot: benefits_swift_copilot,
    ai_insights: benefits_ai_insights,
    workflows: benefits_workflows,
};

export const testimonialsImages = {
    apple_logo,
    google_logo,
    open_ai_logo,
    testimonial_avatar_1,
    testimonial_avatar_2,
    testimonial_avatar_3,
};

export const tryForFreeImages = {
    Logomark,
};

export const blogHeaderImages = {
    header_img_article_1,
    header_img_article_2,
    header_img_article_3,
    header_img_article_4,
    header_img_article_5,
    header_img_article_6,
    
};

export const secondArticle = {
    article_2_img_1,
};

export const thirdArticle = {
    article_3_img_1,
    article_3_img_2,
    article_3_img_3,
};

export const fourthArticle = {
    article_4_img_1,
    article_4_img_2,
    article_4_img_3,
    article_4_img_4,
    article_4_img_5,
    article_4_img_6,
    article_4_img_7,
    article_4_img_8,
    article_4_img_9,
    article_4_img_10,
    article_4_img_11,
    article_4_img_12,
    article_4_img_13,
    article_4_img_14,
    article_4_img_15,
    article_4_img_16,
    article_4_img_17,
    article_4_img_18,
    article_4_img_19,
    article_4_img_20,
    article_4_img_21,
    article_4_img_22,
    article_4_img_23,
    article_4_img_24,
};

export const fifthArticle = {
    article_5_img_1,
    article_5_img_2,
    article_5_img_3,
    article_5_img_4,
    article_5_img_5,
    article_5_img_6,
    article_5_img_7,
    article_5_img_8,
    article_5_img_9,
    article_5_img_10,
    article_5_img_11,
    article_5_img_12,
    article_5_img_13,
    article_5_img_14,
    article_5_img_15,
    article_5_img_16,
    article_5_img_17,
    article_5_img_18,
    article_5_img_19,
    article_5_img_20,
    article_5_img_21,
    article_5_img_22,
    article_5_img_23,
    article_5_img_24,
    article_5_img_25,
    article_5_img_26,
    article_5_img_27,
    article_5_img_28,
    article_5_img_29,
    article_5_img_30,
    article_5_img_31,
    article_5_img_32,
    article_5_img_33,
    article_5_img_34,
    article_5_img_35,
    article_5_img_36,
    article_5_img_37,
    article_5_img_38,
    article_5_img_39,
    article_5_img_40,
    article_5_img_41,
    article_5_img_42,
    article_5_img_43,
    article_5_img_44,
    article_5_img_45,
};

export const sixthArticle = {
    article_6_img_1: article_5_img_1,
    article_6_img_2: article_5_img_2,
    article_6_img_3,
    article_6_img_4,
    article_6_img_5,
    article_6_img_6,
    article_6_img_7,
    article_6_img_8,
    article_6_img_9,
    article_6_img_10,
    article_6_img_11,
    article_6_img_12,
    article_6_img_13,
    article_6_img_14,
    article_6_img_15,
    article_6_img_16,
    article_6_img_17,
    article_6_img_18,
    article_6_img_19,
    article_6_img_20,
};

export const awardsImages = {
    product_of_the_week,
};