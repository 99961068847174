import React from 'react';
import { Box, Typography, Grid } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import BlogAnchorNav from "./BlogAnchorNav";
import { useTranslation } from 'react-i18next';
import Link from "@mui/material/Link";
import Header from '../../pages/LandingPage/Header';
import Footer from '../../pages/LandingPage/Footer';
import { blogHeaderImages, thirdArticle } from "../../utils/imageUtils";
import "./index.css";

const BlogThree = () => {
    const { t } = useTranslation();

    const sections = [
        { id: "section1", title: t("Who reigns supreme?") },
        { id: "section2", title: t("From the beginning") },
        { id: "section3", title: t("Main purpose") },
        { id: "section4", title: t("The retrieval of information") },
        { id: "section5", title: t("App Marketplace") },
        { id: "section6", title: t("So, who is the winner?") },
    ];

    const imageMap = {
        header_img: blogHeaderImages.header_img_article_3,
        img_1: thirdArticle.article_3_img_1,
        img_2: thirdArticle.article_3_img_2,
        img_3: thirdArticle.article_3_img_3,
    };

    const links = {
        blog: "/blog",
        intelswiftSite: "https://intelswift.com/",
    }; 

    return (
        <>
            <Header />
            <Box className="breadcrumbs-container">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" href={links.blog} className="breadcrumbs-link">
                        {t("blogThree.breadcrumbsLinkBlog")}
                    </Link>
                    <Typography color="text.primary">{t("blogThree.blogOneTitle")}</Typography>
                </Breadcrumbs>
            </Box>
            <div className="blog-post-title-container">
                <div className="left-section">
                    <Typography className="blog-post-title" variant="h1" gutterBottom>
                        {t("blogThree.blogOneTitle")}
                    </Typography>
                    <Typography className="blog-post-subtitle" variant="h2" gutterBottom>
                        {t("blogThree.blogOneSubtitle")}
                    </Typography>
                    <div className="meta-info">
                        <Typography variant="subtitle1">{t("blogThree.blogPostBy")}</Typography>
                        <Typography variant="subtitle1">•</Typography>
                        <Typography variant="subtitle1">{t("blogThree.blogPostDate")}</Typography>
                        <Typography variant="subtitle1">•</Typography>
                        <Typography variant="subtitle1">{t("blogThree.blogPostTimeRead")}</Typography>
                    </div>
                </div>
                <div className="right-section">
                    <section className="blog-image">
                        <img src={imageMap.header_img} />
                    </section>
                </div>
            </div>
            <Grid container spacing={2} className="blog-post-layout">
                <Grid item xs={12} md={3}>
                    <BlogAnchorNav sections={sections} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box id="section1">
                        <Typography variant="body1"> {t("blogThree.firsParagraphtLineOne")}<br /><br />
                            {t("blogThree.firsParagraphtLineTwo")} </Typography>
                    </Box>
                    <br /><Box id="section2" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogThree.firsParagraphtTitle")}  </Typography>
                        <Typography variant="body1"><strong>{t("blogThree.secondParagraphtLineOneStrong")}</strong>{t("blogThree.secondParagraphtLineOne")}
                            <br /><br />
                            {t("blogThree.secondParagraphtLineTwo")}
                            <br /><br />{t("blogThree.secondParagraphtLineThree")} </Typography>
                    </Box>
                    <br /><Box id="section3" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogThree.thirdParagraphtTitle")} </Typography>
                        <Typography variant="body1">{t("blogThree.thirdParagraphtLineOne")} </Typography>
                        <img src={imageMap.img_1} />
                        <img src={imageMap.img_2} />
                        <Typography variant="body1">{t("blogThree.thirdParagraphtLineTwo")}</Typography>
                        <Typography variant="body1">{t("blogThree.thirdParagraphtLineThree")} </Typography>
                    </Box>
                    <br /><Box id="section4" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogThree.fourthParagraphtTitle")}</Typography>
                        <Typography variant="body1">{t("blogThree.fourthParagraphtLineOne")}
                            <br /><br /> {t("blogThree.fourthParagraphtLineTwo")}
                            <br /><br /> {t("blogThree.fourthParagraphtLineThree")}</Typography>
                    </Box>
                    <br /><Box id="section5" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogThree.fifthParagraphtTitle")}</Typography>
                        <Typography variant="body1">{t("blogThree.fifthParagraphtLineOne")}</Typography>
                        <img src={imageMap.img_3} />
                        <Typography variant="body1">{t("blogThree.fifthParagraphtLineTwo")}</Typography>
                    </Box>
                    <br /><Box id="section6" className="blog-paragraph">
                    <Typography variant="h4" gutterBottom>{t("blogThree.sixthParagraphtTitle")}</Typography>
                    <Typography variant="body1">{t("blogThree.sixthParagraphtLineOne")}
                            <br /><br /> {t("blogThree.sixthParagraphtLineTwo")} <a href={links.intelswiftSite} target="_blank" rel="noopener noreferrer">{t("blogThree.sixthParagraphtLink")} </a>{t("blogThree.sixthParagraphtLineThree")}<br /><br />{t("blogThree.sixthParagraphtLineFour")}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}
export default BlogThree;