import React, { useState, useEffect } from "react";
import { Link as ScrollLink, Events, scrollSpy } from "react-scroll";
import { List, ListItem, ListItemText, TextField, Box } from "@mui/material";

const BlogAnchorNav = ({ sections }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeSection, setActiveSection] = useState(null);

  const filteredSections = sections.filter((section) =>
    section.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Register scroll events and activate scrollSpy
  useEffect(() => {
    Events.scrollEvent.register("begin", (to, element) => {
      console.log("Scrolling to:", to);
    });

    Events.scrollEvent.register("end", (to, element) => {
      console.log("Reached:", to);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const handleSetActive = (sectionId) => {
    setActiveSection(sectionId);
  };

  return (
    <Box className="sticky-nav">
      <TextField
        label="Search Sections"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <List className="sticky-nav-list">
        {filteredSections.map((section) => (
          <ListItem
            key={section.id}
            button
            style={{
              fontWeight: activeSection === section.id ? "bold" : "normal",
              color: activeSection === section.id ? "rgba(141, 43, 255, 1)" : "inherit",
            }}
          >
            <ScrollLink
              to={section.id}
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              onSetActive={() => handleSetActive(section.id)}
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              <ListItemText primary={section.title} />
            </ScrollLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default BlogAnchorNav;